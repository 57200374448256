import React from "react";
import swyveryLogo from "../assets/swyvery-logo.png";

const TopBar = () => {
  return (
    <div className="w-full bg-white flex items-center border-b border-gray-200 pl-10 h-20 fixed top-0 z-20">
      <img src={swyveryLogo} alt="Swyvery" />
      <p className="font-inter text-2xl font-normal text-[#0B4353]">SWYVERY</p>
    </div>
  );
};

export default TopBar;
