import React, { useEffect, useState } from "react";
import { BsBarChartLine } from "react-icons/bs";
import { CiGlobe, CiSettings } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  MdDiscount,
  MdOutlineFeedback,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";
import {
  RiAdminFill,
  RiNotification2Line,
  RiUserShared2Fill,
} from "react-icons/ri";
import { VscRequestChanges } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/slices/auth";
import { useAppDispatch } from "../../Redux/store";
import ApiManager, { instance } from "../../utils/ApiManager";
import { BiSolidOffer } from "react-icons/bi";
import { CiLogout } from "react-icons/ci";
import SidebarLink from "./SidebarLink";
import Button from "../Button";

const Sidebar = () => {
  const [unReviewedDriverCount, setUnreviewedDriverCount] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getUnReviewedDriverCount = async () => {
    try {
      const res = await instance.get("/admin/driver/unreviewed/count");
      if (res.status === 200) {
        setUnreviewedDriverCount(res.data?.count);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUnReviewedDriverCount();
  }, []);

  const handleLogout = async () => {
    new ApiManager().logout();
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div
      className={`font-inter flex pl-10 pt-4 items-center flex-col fixed bg-white border-r-4 border-black top-20 left-0 h-screen w-1/4`}
    >
      <div className="w-full flex flex-col gap-3 overflow-y-auto overflow-x-hidden ">
        <SidebarLink
          icon={BsBarChartLine}
          label="Dashboard"
          link="/dashboard"
        />
        <SidebarLink icon={FaUsers} label="Users" link="/dashboard/users" />
        <SidebarLink
          renderRight={
            unReviewedDriverCount > 0 ? (
              <span className="bg-green-100 ml-2 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                {unReviewedDriverCount}
              </span>
            ) : null
          }
          icon={RiUserShared2Fill}
          label="Drivers"
          link="/dashboard/drivers"
        />
        <SidebarLink
          link="/dashboard/services"
          icon={MdOutlineProductionQuantityLimits}
          label="Services"
        />
        <SidebarLink
          link="/dashboard/feedback"
          icon={MdOutlineFeedback}
          label="Feedback"
        />
        <SidebarLink
          link="/dashboard/submit-requests"
          icon={VscRequestChanges}
          label="Submission Requests"
        />
        <SidebarLink
          link="/dashboard/help-desk"
          icon={IoIosHelpCircleOutline}
          label="Help Desk"
        />
        <SidebarLink
          link="/dashboard/notification"
          label="Notifications"
          icon={RiNotification2Line}
        />
        <SidebarLink icon={CiGlobe} label="States" link="/dashboard/states" />
        <SidebarLink
          icon={RiAdminFill}
          label="Admins"
          link="/dashboard/admins"
        />

        <SidebarLink
          icon={MdDiscount}
          label="Promo Codes"
          link="/dashboard/promo-codes"
        />
        <SidebarLink
          icon={BiSolidOffer}
          label="Offers"
          link="/dashboard/offers"
        />

        <SidebarLink
          link="/dashboard/accountSettings"
          label="Settings"
          icon={CiSettings}
        />
      </div>
      <div className="w-full mt-10">
        <Button
          onClick={handleLogout}
          className="btn-ghost  text-red-600 px-0 "
        >
          <CiLogout size={20} className="text-red-600" />
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
