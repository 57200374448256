import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useGetAllComplaintsQuery } from "../api/helpDesk/helpDesk";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import ViewDetails from "../components/HelpDesk/ViewDetails";
import Pagination from "../components/Pagination.js";
import Spinner from "../components/Spinner.jsx";
import { capitalize } from "../utils/helpers";
import { Link } from "react-router-dom";
import Button from "../components/Button";

interface ComplaintBaseResponse {
  createdAt: string;
  curbside_pickup_meta: {};
  description: string;
  service: {};
  userType: "user" | "driver";
  _id: string;
  title: string;
  serviceId: string;
}

interface UserResponse extends ComplaintBaseResponse {
  userType: "user";
  reportingUser: {
    email: string;
    fullName: string;
    gender: string;
    phone: string;
    profileImage: string;
    _id: string;
  };
}

interface DriverResponse extends ComplaintBaseResponse {
  userType: "driver";
  reportingDriver: {
    email: string;
    fullName: string;
    gender: string;
    phone: string;
    profileImage: string;
    _id: string;
    vehicleNumber: string;
    vehicleType: string;
  };
}

type ApiResponse = UserResponse | DriverResponse;

const HelpDesk = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [userType, setUserType] = useState("");
  const [selectedComplaint, setSelectedComplaint] = useState("");

  const { data, isFetching } = useGetAllComplaintsQuery({
    limit: 10,
    page: currentPage,
    userType,
  });

  return (
    <>
      <Helmet>
        <title> Help Desk</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="py-2  flex flex-col justify-center font-inter bg-gray-100 ">
          <div className="flex items-center justify-end mb-4">
            <select
              defaultValue=""
              className="outline-none bg-white rounded-lg h-12 pl-4 pr-2 ml-4"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value="" disabled hidden>
                User Type
              </option>
              <option value="user">User</option>
              <option value="driver">Driver</option>
            </select>
            <Button
              disabled={userType === ""}
              type="button"
              className="btn-outline w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary"
              onClick={() => setUserType("")}
            >
              Clear
            </Button>
          </div>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isFetching ? (
              <Spinner />
            ) : !isFetching && data?.docs?.length === 0 ? (
              <div className="w-full  h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Data Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>User</TH>
                    <TH>Title</TH>
                    <TH>Type</TH>
                    <TH>Description</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {data?.docs?.map((item: ApiResponse, index: number) => (
                    <TR key={index}>
                      <TH>
                        {item?.userType === "user"
                          ? item?.reportingUser?.fullName || "N/A"
                          : item?.reportingDriver?.fullName || "N/A"}
                      </TH>
                      <TH className="flex items-center">
                        <p className="shrink-0">
                          {item?.title?.slice(0, 30) || "N/A"}{" "}
                        </p>
                        {item?.title?.length > 30 && (
                          <p
                            className="cursor-pointer shrink-0 text-xs"
                            onClick={() => setSelectedComplaint(item?._id)}
                          >
                            ...Read More
                          </p>
                        )}
                      </TH>
                      <TH>{capitalize(item?.userType)}</TH>
                      <TH className="flex items-center">
                        {item?.description === "" ? (
                          <p>N/A</p>
                        ) : (
                          <>
                            <p className="shrink-0">
                              {item?.description?.slice(0, 30)}
                            </p>

                            {item?.description?.length > 30 && (
                              <p
                                className="cursor-pointer text-xs shrink-0"
                                onClick={() => setSelectedComplaint(item?._id)}
                              >
                                ...Read More
                              </p>
                            )}
                          </>
                        )}
                      </TH>
                      <TH>
                        <div className="flex items-center">
                          <button
                            onClick={() => setSelectedComplaint(item?._id)}
                            className="text-primary mr-10"
                          >
                            View
                          </button>
                          <Link to={`/dashboard/services/${item?.serviceId}`}>
                            <button className="text-primary">
                              View Service Details
                            </button>
                          </Link>
                        </div>
                      </TH>
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              page={data?.totalPages}
              handlePageClick={(e: { selected: number }) => {
                setCurrentPage(e.selected + 1);
              }}
            />
          </div>
        </div>
      </div>

      <ViewDetails
        isVisible={selectedComplaint !== ""}
        onHide={() => setSelectedComplaint("")}
        id={selectedComplaint}
      />
    </>
  );
};

export default HelpDesk;
