// @ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { AiOutlineClose } from "react-icons/ai";
import amazing from "../assets/amazing-outline.png";
import average from "../assets/average-outline.png";
import bad from "../assets/bad-outline.png";
import amazingHighlighted from "../assets/feedback-amazing.png";
import averageHighlighted from "../assets/feedback-average.png";
import badHighlighted from "../assets/feedback-bad.png";
import goodHighlighted from "../assets/feedback-good.png";
import terribleHighlighted from "../assets/feedback-terrible.png";
import good from "../assets/good-outline.png";
import terrible from "../assets/terrible-outline.png";
import { formatDate } from "../utils/helpers";
import ExperienceRating from "./ExperienceRating";
import { experienceRatingMap } from "./Feedback";
import Rating from "./Rating.js";
import Modal from "./Modal";
import { Link } from "react-router-dom";

const emojis = [
  {
    experienceRating: 1,
    inactive: terrible,
    active: terribleHighlighted,
  },
  { experienceRating: 2, inactive: bad, active: badHighlighted },
  {
    experienceRating: 3,
    inactive: average,
    active: averageHighlighted,
  },
  { experienceRating: 4, inactive: good, active: goodHighlighted },
  {
    experienceRating: 5,
    inactive: amazing,
    active: amazingHighlighted,
  },
];

const ViewFeedback = ({ isVisible, setVisible, feedback, screen }) => {
  return (
    <Modal isVisible={isVisible} onHide={() => setVisible(false)}>
      <div className="w-full flex flex-col justify-center gap-4 h-full">
        {screen !== "driverDetails" &&
          (feedback?.userType === "user" ? (
            <p>
              <span className="font-bold">User Name: </span>
              {/* <Link to={`/dashboard/users/${feedback?.user?._id}`}> */}
              <span className="text-primary underline">{feedback?.user}</span>
              {/* </Link> */}
            </p>
          ) : (
            <p>
              <span className="font-bold">Driver Name: </span>
              {/* <Link to={`/dashboard/drivers/${feedback?.driver?._id}`}> */}
              <span className="text-primary underline">{feedback?.driver}</span>
              {/* </Link> */}
            </p>
          ))}
        {screen !== "driverDetails" && (
          <div className="flex items-center justify-between">
            <p>
              <strong>Device: </strong>
              {feedback?.device}
            </p>
            <p>
              <strong>Build: </strong>
              {feedback?.build}
            </p>
            <p>
              <strong>Date: </strong>
              {formatDate(feedback?.createdAt)}
            </p>
          </div>
        )}
        <p>
          <span className="font-bold">Feedback: </span>
        </p>
        <div className="border border-primary rounded-xl p-4">
          {feedback?.feedback ? feedback.feedback : "N/A"}
        </div>
        <p>
          <span className="font-bold">Experience Rating: </span>
        </p>
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center gap-4">
            {screen === "driverDetails" ? (
              <Rating fontSize="2rem" rating={feedback?.experienceRating} />
            ) : (
              <ExperienceRating feedback={feedback} />
            )}
          </div>
          {screen !== "driverDetails" && (
            <p className="mt-4">
              {experienceRatingMap[feedback?.experienceRating]}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewFeedback;
