import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import Modal from "./Modal";
import Input from "./Input";
import PasswordInput from "./PasswordInput";
import Button from "./Button";
import { ADMIN_TYPES } from "../utils/Enums";
import { twMerge } from "tailwind-merge";
import { useCreateAdminMutation } from "../api/admins";
import { toast } from "react-toastify";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
}

const adminOptions = [
  {
    label: "Admin",
    value: ADMIN_TYPES.ADMIN,
  },
  {
    label: "Super Admin",
    value: ADMIN_TYPES.SUPER_ADMIN,
  },
];

const AddAdminModal = ({ isVisible, onHide }: PropTypes) => {
  const [createAdmin, { isLoading }] = useCreateAdminMutation();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userType: null,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().trim().required("This field is required"),
      lastName: Yup.string().trim().required("This field is required"),
      email: Yup.string().trim().email().required("This field is required"),
      password: Yup.string()
        .trim()
        .min(6, "Password must be at least 6 characters")
        .required("This field is required"),
      userType: Yup.string().required("Please select a user type"),
    }),
    onSubmit: async (values) => {
      const res: any = await createAdmin(values);
      if (res?.error) {
        toast(res.error?.data?.error, { type: "error" });
      } else {
        toast(res?.data?.message, { type: "success" });
        formik.resetForm();
        onHide();
      }
    },
  });

  return (
    <Modal
      isVisible={isVisible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      heading="Add Admin"
    >
      <form onSubmit={formik.handleSubmit} className="grid grid-cols-2 gap-4">
        <Input
          name="firstName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="First Name *"
          className="input-primary"
          placeholder="Enter First Name"
          isInvalid={Boolean(
            formik.touched.firstName && formik.errors.firstName
          )}
          error={formik.errors.firstName}
        />
        <Input
          name="lastName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Last Name *"
          className="input-primary"
          placeholder="Enter Last Name"
          isInvalid={Boolean(formik.touched.lastName && formik.errors.lastName)}
          error={formik.errors.lastName}
        />
        <Input
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Email *"
          autoComplete="off"
          type="email"
          className="input-primary"
          placeholder="Enter Email"
          isInvalid={Boolean(formik.touched.email && formik.errors.email)}
          error={formik.errors.email}
        />
        <PasswordInput
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Password *"
          className="input-primary"
          placeholder="Enter Password"
          autoComplete="new-password"
          isInvalid={Boolean(formik.touched.password && formik.errors.password)}
          error={formik.errors.password}
        />
        <div className="col-span-2">
          <div className="join w-full">
            {adminOptions.map((option, index) => (
              <Button
                onClick={() => formik.setFieldValue("userType", option.value)}
                key={option.value + index}
                className={twMerge(
                  "join-item w-1/2 btn-secondary text-black",
                  formik.values.userType === option.value &&
                    "bg-primary text-white"
                )}
                type="button"
              >
                {option.label}
              </Button>
            ))}
          </div>
          {formik.touched.userType && formik.errors.userType ? (
            <div className="label">
              <span className="label-text-alt text-red-600">
                {formik.errors.userType}
              </span>
            </div>
          ) : null}
        </div>
        <div className="col-span-2 text-center">
          <Button isLoading={isLoading} type="submit" className="px-10">
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddAdminModal;
