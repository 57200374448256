import React, { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { useCreateAdminMutation } from "../api/admins";
import Spinner from "../components/Spinner.jsx";
import { useAppSelector } from "../Redux/store";
import { ADMIN_TYPES } from "../utils/Enums";
import { allValuesPresent, validateEmail } from "../utils/helpers";

type FormType = {
  userType: SingleValue<{ label: string; value: string }>;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const adminOptions = [
  {
    label: "Admin",
    value: ADMIN_TYPES.ADMIN,
  },
  {
    label: "Super Admin",
    value: ADMIN_TYPES.SUPER_ADMIN,
  },
];

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  userType: null,
};

const AddAdmin = () => {
  const [form, setForm] = useState<FormType>(initialState);
  const [isViewingPassword, setIsViewingPassword] = useState(false);

  const [createAdmin, { isLoading }] = useCreateAdminMutation();

  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const present = allValuesPresent(form);
    if (!present) {
      toast("Please enter all required fields", { type: "error" });
      return;
    }
    if (!form.userType) {
      toast("Please Select a user type", { type: "error" });
      return;
    }
    if (!validateEmail(form.email)) {
      toast("Please enter a valid email", { type: "error" });
      return;
    }
    const res: any = await createAdmin({
      ...form,
      userType: form.userType?.value,
    });
    if (res?.error) {
      toast(res.error?.data?.error, { type: "error" });
    } else {
      toast(res?.data?.message, { type: "success" });
      setForm(initialState);
      navigate("/dashboard/admins");
    }
  };

  const options = useMemo(() => {
    return user?.userType === ADMIN_TYPES.SUPER_ADMIN
      ? adminOptions
      : adminOptions.filter((op) => op.value === ADMIN_TYPES.ADMIN);
  }, [user]);

  return (
    <div>
      <form className="grid grid-cols-2" onSubmit={handleSubmit}>
        <div className="flex flex-col mt-4">
          <label htmlFor="firstName">First Name *</label>
          <input
            onChange={handleFormChange}
            className="w-96 px-4 rounded outline-none py-3 mt-2"
            value={form.firstName}
            name="firstName"
            required
            id="firstName"
            placeholder="Enter First Name"
          />
        </div>
        <div className="flex flex-col mt-4">
          <label htmlFor="lastName">Last Name *</label>
          <input
            name="lastName"
            onChange={handleFormChange}
            value={form.lastName}
            className="w-96 px-4 rounded outline-none py-3 mt-2"
            id="lastName"
            placeholder="Enter Last Name"
            required
          />
        </div>
        <div className="flex flex-col mt-4">
          <label htmlFor="email">Email *</label>
          <input
            onChange={handleFormChange}
            value={form.email}
            name="email"
            required
            className="w-96 px-4 rounded outline-none py-3 mt-2"
            type="email"
            id="email"
            placeholder="Enter Email"
            autoComplete="off"
          />
        </div>
        <div className="flex flex-col mt-4">
          <label htmlFor="password">Password *</label>
          <div className="flex w-96 items-center border mt-2 outline-none rounded px-4 py-3 bg-white justify-between">
            <input
              className="w-96 focus:outline-none"
              type={isViewingPassword ? "text" : "password"}
              onChange={handleFormChange}
              value={form.password}
              name="password"
              id="password"
              required
              placeholder="Enter Password"
              autoComplete="new-password"
            />
            {isViewingPassword ? (
              <IoIosEyeOff
                className="cursor-pointer"
                size={24}
                onClick={() => setIsViewingPassword((prev) => !prev)}
              />
            ) : (
              <IoIosEye
                className="cursor-pointer"
                size={24}
                onClick={() => setIsViewingPassword((prev) => !prev)}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <p className="mb-4">Role *</p>
          <Select
            placeholder="Please Select A Role"
            name="userType"
            value={form.userType}
            onChange={(e) => setForm({ ...form, userType: e })}
            className="w-96"
            options={options}
            required
          />
        </div>
        <button
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          className={`bg-primary w-32 mt-10 text-white rounded px-4 py-2`}
        >
          {isLoading && <Spinner variant="svg" />}
          Add
        </button>
      </form>
    </div>
  );
};

export default AddAdmin;
