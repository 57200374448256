import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiManager from "../utils/ApiManager";
import BackButton from "./BackButton";
import Map from "./Map";
import Timeline from "./Timeline";
import Rating from "./Rating";
import { twMerge } from "tailwind-merge";
import { serviceTypeMap } from "./ServicesDashboard";
import ConfirmationDialog from "./ConfirmationDialog";
import { toast } from "react-toastify";

const Item = ({ heading, subheadings = [] }) => {
  return (
    <div>
      <h1 className="text-xl font-bold mb-4">{heading}</h1>
      {subheadings.map((item, index) => (
        <div key={index} className="flex items-start mb-2">
          <h3 className="font-semibold mr-2">{item.heading}</h3>
          {item.element ? item.element : <p>{item.description}</p>}
        </div>
      ))}
    </div>
  );
};

const ServiceDetails = () => {
  const { id } = useParams();

  const [serviceInfo, setServiceInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [isCancellingService, setIsCancellingService] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState("");

  const getServiceInfo = async () => {
    setLoading(true);
    const res = await new ApiManager().getServiceById(id);
    if (res.success) {
      setServiceInfo(res.payload.data);
      console.log(res.payload.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getServiceInfo();
  }, []);

  const statusMap = (status) => {
    const map = {
      arrivedAtDropoff: {
        text: "Arrived At Dropoff",
      },
      unassigned: {
        text: "Unassigned",
      },
      notProcessable: {
        text: "Not Processable",
      },
      assigned: {
        text: "Assigned",
      },
      wayToStore: {
        text: "Way To Store",
      },
      arrivedAtPickup: {
        text: "Arrived At Pickup",
      },
      confirmItems: {
        text: "Confirm Items",
      },
      proceedToDropoff: {
        text: "Proceed To Dropoff",
      },
      confirmDropoff: {
        text: "Confirm Dropoff",
      },
      delivered: {
        text: "Delivered",
      },
      ratedByUser: {
        text: "Completed",
      },
      ratedByDriver: {
        text: "Rated By Driver",
      },
      deliver: {
        text: "Deliver",
      },
      completed: {
        text: "Completed",
      },
      cancelled: {
        text: "Cancelled",
      },
    };

    const text = map[status]?.text;
    return text;
  };

  const handleServiceCancel = async () => {
    setIsCancellingService(true);
    const res = await new ApiManager().cancelService(selectedServiceId);
    if (res?.status === 200) {
      const data = { ...serviceInfo };
      data.status = "cancelled";
      setServiceInfo(data);
      setSelectedServiceId("");
      toast("Service Deleted Successfully", { type: "success" });
    } else {
      setSelectedServiceId("");
      toast("Couldn't cancel service at this moment. Please try again later.", {
        type: "error",
      });
    }
    setIsCancellingService(false);
  };

  return (
    <>
      <div className="flex-col  bg-gray-100  min-h-screen py-10 flex justify-center ">
        <BackButton link="/dashboard/services" />
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-3xl">Service Details</h1>
          <span className="flex-1 mx-4 h-1 bg-gray-300"></span>
          {!loading &&
          serviceInfo?.status !== "ratedByUser" &&
          serviceInfo?.status !== "cancelled" ? (
            <button
              onClick={() => setSelectedServiceId(id)}
              className="text-red-600"
            >
              Cancel Service
            </button>
          ) : null}
        </div>
        <div className="w-full bg-white py-4 px-4 rounded-lg shadow-xl flex">
          {loading === true ? (
            <div className="w-full h-full flex items-center justify-center">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-5">
              <div>
                <Item
                  heading="General"
                  subheadings={[
                    ...(serviceInfo?.serviceMetaType !== "package_pickup_meta"
                      ? [
                          {
                            heading: "Description: ",
                            description:
                              serviceInfo?.serviceMeta?.pickupLocation
                                ?.description || "N/A",
                          },
                          {
                            heading: "No of Items: ",
                            description:
                              serviceInfo?.serviceMeta?.pickupLocation
                                ?.numOfItems || "N/A",
                          },
                          {
                            heading: "Store Name: ",
                            description:
                              serviceInfo?.serviceMeta?.pickupLocation
                                ?.storeName,
                          },
                          {
                            heading: "Store Website: ",
                            description:
                              serviceInfo?.serviceMeta?.pickupLocation
                                ?.storeWebsite || "N/A",
                          },
                        ]
                      : []),
                    ...(serviceInfo?.serviceMetaType === "curbside_pickup_meta"
                      ? [
                          {
                            heading: "Link For Confirmation: ",
                            description:
                              serviceInfo?.serviceMeta?.pickupLocation
                                ?.linkForConfirmation,
                          },
                        ]
                      : serviceInfo?.serviceMetaType === "instore_pickup_meta"
                      ? [
                          {
                            heading: "Pickup Instructions",
                            description:
                              serviceInfo?.serviceMeta?.pickupInstruction ||
                              "N/A",
                          },
                        ]
                      : []),
                    {
                      heading: "Type: ",
                      description: serviceTypeMap[serviceInfo?.serviceMetaType],
                    },
                    {
                      heading: "Status: ",
                      element: (
                        <p
                          className={twMerge(
                            "text-white rounded-full px-4 py-1",
                            serviceInfo?.status === "ratedByUser" ||
                              serviceInfo?.status === "delivered"
                              ? "bg-green-500"
                              : serviceInfo?.state === "unassigned"
                              ? "bg-yellow-500"
                              : serviceInfo?.status === "cancelled"
                              ? "bg-red-500"
                              : "bg-yellow-500"
                          )}
                        >
                          {statusMap(serviceInfo?.status)}
                        </p>
                      ),
                    },
                    ...(serviceInfo?.ratingByUser
                      ? [
                          {
                            heading: "Rating By User",
                            element: (
                              <Rating
                                rating={serviceInfo?.ratingByUser?.rating}
                              />
                            ),
                          },
                        ]
                      : []),
                    ...(serviceInfo?.ratingByDriver
                      ? [
                          {
                            heading: "Rating By Driver",
                            element: (
                              <Rating
                                rating={serviceInfo?.ratingByDriver?.rating}
                              />
                            ),
                          },
                        ]
                      : []),
                  ]}
                />
                {serviceInfo?.serviceMeta?.pickupLocation?.proofOfPurchase ? (
                  <a
                    href={
                      serviceInfo?.serviceMeta?.pickupLocation?.proofOfPurchase
                    }
                    target="_blank"
                  >
                    <button className="bg-primary rounded px-10 py-2 text-white">
                      Proof of Purchase
                    </button>
                  </a>
                ) : null}
              </div>
              <Item
                heading="User"
                subheadings={[
                  {
                    heading: "Name: ",
                    description: serviceInfo?.user
                      ? serviceInfo?.user?.fullName
                      : "N/A",
                  },
                  {
                    heading: "Email: ",
                    description: serviceInfo?.user
                      ? serviceInfo?.user?.email
                      : "N/A",
                  },
                  {
                    heading: "Phone: ",
                    description: serviceInfo?.user
                      ? serviceInfo?.user?.phone
                      : "N/A",
                  },
                ]}
              />
              <Item
                heading="Driver"
                subheadings={[
                  {
                    heading: "Name: ",
                    description: serviceInfo?.driver
                      ? serviceInfo?.driver?.fullName
                      : "N/A",
                  },
                  {
                    heading: "Email: ",
                    description: serviceInfo?.driver
                      ? serviceInfo?.driver?.email
                      : "N/A",
                  },
                  {
                    heading: "Phone: ",
                    description: serviceInfo?.driver
                      ? serviceInfo?.driver?.phone
                      : "N/A",
                  },
                ]}
              />
              <Item
                heading="Payment"
                subheadings={[
                  {
                    heading: "User's Total: ",
                    description: serviceInfo?.userTotal
                      ? `$${serviceInfo?.userTotal?.toFixed(2)}`
                      : "N/A",
                  },
                  {
                    heading: "Driver's Total",
                    description: serviceInfo?.driverTotal
                      ? `$${serviceInfo?.driverTotal?.toFixed(2)}`
                      : "N/A",
                  },
                  {
                    heading: "Swyvery Total: ",
                    description: serviceInfo?.serviceCharges
                      ? `$${serviceInfo?.serviceCharges?.toFixed(2)}`
                      : "N/A",
                  },
                ]}
              />
              <Item
                heading="Pickup Location"
                subheadings={[
                  {
                    heading: "State: ",
                    description:
                      serviceInfo?.serviceMeta?.pickupLocation?.state,
                  },
                  {
                    heading: "City: ",
                    description: serviceInfo?.serviceMeta?.pickupLocation?.city,
                  },
                  {
                    heading: "Address: ",
                    description:
                      serviceInfo?.serviceMeta?.pickupLocation?.address,
                  },
                  {
                    heading: "Country: ",
                    description:
                      serviceInfo?.serviceMeta?.pickupLocation?.country,
                  },
                  ...(serviceInfo?.serviceMetaType === "package_pickup_meta"
                    ? [
                        {
                          heading: "Sender Name: ",
                          description:
                            serviceInfo?.serviceMeta?.pickupLocation
                              ?.firstName +
                            " " +
                            serviceInfo?.serviceMeta?.pickupLocation?.lastName,
                        },
                        {
                          heading: "Sender Number: ",
                          description:
                            serviceInfo?.serviceMeta?.pickupLocation
                              ?.phoneNumber,
                        },
                      ]
                    : []),
                  ...(serviceInfo?.serviceMeta?.pickupLocation?.addressCtd
                    ? [
                        {
                          heading: "Apartment No: ",
                          description:
                            serviceInfo?.serviceMeta?.pickupLocation
                              ?.addressCtd,
                        },
                      ]
                    : []),
                ]}
              />
              <Item
                heading="Delivery Location"
                subheadings={[
                  {
                    heading: "State: ",
                    description:
                      serviceInfo?.serviceMeta?.deliveryLocation?.state,
                  },
                  {
                    heading: "City: ",
                    description:
                      serviceInfo?.serviceMeta?.deliveryLocation?.city,
                  },
                  {
                    heading: "Address",
                    description:
                      serviceInfo?.serviceMeta?.deliveryLocation?.address,
                  },
                  {
                    heading: "Country: ",
                    description:
                      serviceInfo?.serviceMeta?.deliveryLocation?.country,
                  },
                  ...(serviceInfo?.serviceMetaType === "package_pickup_meta"
                    ? [
                        {
                          heading: "Recipient Name: ",
                          description:
                            serviceInfo?.serviceMeta?.deliveryLocation
                              ?.contactName,
                        },
                        {
                          heading: "Recipient Number: ",
                          description:
                            serviceInfo?.serviceMeta?.deliveryLocation?.phone,
                        },
                      ]
                    : [
                        {
                          heading: "Recipient Name: ",
                          description:
                            serviceInfo?.serviceMeta?.deliveryLocation
                              ?.contactName,
                        },
                        {
                          heading: "Recipient Number: ",
                          description:
                            serviceInfo?.serviceMeta?.deliveryLocation?.phone,
                        },
                      ]),
                  ...(serviceInfo?.serviceMeta?.deliveryLocation?.addressCtd
                    ? [
                        {
                          heading: "Apartment No: ",
                          description:
                            serviceInfo?.serviceMeta?.deliveryLocation
                              ?.addressCtd,
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          )}
        </div>
        <div className="w-full bg-white py-4 px-4 rounded-lg shadow-xl flex mt-10">
          {loading === true ? (
            <div className="w-full h-full flex items-center justify-center">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              <div className="w-full h-full">
                {loading === true ? (
                  <div className="w-full h-full flex items-center justify-center">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="relative w-full h-72">
                    <Map
                      lngg={serviceInfo?.serviceMeta?.deliveryLocation?.lng}
                      latt={serviceInfo?.serviceMeta?.deliveryLocation?.lat}
                      pickupLatt={serviceInfo?.serviceMeta?.pickupLocation?.lat}
                      pickupLngg={serviceInfo?.serviceMeta?.pickupLocation?.lng}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <h1 className="text-3xl my-10">Logs</h1>
        <div className="w-full bg-white px-10 py-10">
          <Timeline logs={serviceInfo?.logs} />
        </div>
      </div>
      <ConfirmationDialog
        isSubmitButtonDisabled={isCancellingService}
        isSubmitting={isCancellingService}
        handleClick={handleServiceCancel}
        isVisible={selectedServiceId !== ""}
        onHide={() => setSelectedServiceId("")}
        text="Are you sure you want to cancel this service ?"
      />
    </>
  );
};

export default ServiceDetails;
