import React, { useEffect, useState } from "react";
import ApiManager from "../utils/ApiManager";
import DataListView from "../components/DataListView";
import Button from "../components/Button";
import AddOfferModal from "../components/AddOfferModal";
import { capitalize, formatDate } from "../utils/helpers";
import { formatServiceTypes } from "./PromoCodes";

export type Offer = {
  _id: string;
  discountValue: number;
  discountType: string;
  validFrom: string;
  validTo?: string;
  serviceType: [
    "package_pickup_meta" | "curbside_pickup_meta" | "instore_pickup_meta"
  ];
  pointsRequired: number;
};

const Offers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddOfferModalVisible, setIsAddOfferModalVisible] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleGetAllActiveOffers = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getAllActiveOffers();
    if (res.success) {
      setOffers(res.data);
      setTotalPages(1);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetAllActiveOffers();
  }, []);

  const columns = [
    {
      header: "Points Required",
      accessor: (row: Offer) => row.pointsRequired,
    },
    {
      header: "Discount Value",
      accessor: (row: Offer) =>
        row.discountType === "flat"
          ? `$ ${row.discountValue.toFixed(2)}`
          : `${row.discountValue}%`,
    },
    {
      header: "Discount Type",
      accessor: (row: Offer) => capitalize(row.discountType),
    },
    {
      header: "Service Type",
      accessor: (row: Offer) => formatServiceTypes(row.serviceType),
    },
    {
      header: "Valid From",
      accessor: (row: Offer) => formatDate(row.validFrom),
    },
    {
      header: "Valid To",
      accessor: (row: Offer) => (row.validTo ? formatDate(row.validTo) : "N/A"),
    },
  ];

  return (
    <div className="w-full h-full py-10">
      <div className="flex justify-end mb-10">
        <Button
          variant="outline"
          onClick={() => setIsAddOfferModalVisible(true)}
        >
          Add Offer
        </Button>
      </div>
      <DataListView
        isFetching={isLoading}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        currentPage={currentPage}
        forcePage={currentPage}
        emptyStateMessage="No Data Found"
        columns={columns}
        data={offers}
      />
      <AddOfferModal
        isVisible={isAddOfferModalVisible}
        onHide={() => setIsAddOfferModalVisible(false)}
      />
    </div>
  );
};

export default Offers;
