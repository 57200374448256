import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";

const DashboardLayout = () => {
  return (
    <div className="w-full min-h-screen">
      <TopBar />
      <div className="flex h-full">
        <Sidebar />
        <div className="w-80% px-6 py-2 relative top-20 left-20% flex flex-col justify-center font-inter bg-gray-100 h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
