import { useFormik } from "formik";
import React, { useCallback } from "react";
import * as Yup from "yup";
import {
  discountOptions,
  getTodayDate,
  serviceTypes,
} from "./AddPromoCodeModal";
import Checkbox from "./Checkbox";
import Input from "./Input";
import Modal from "./Modal";
import Select from "./Select";
import Button from "./Button";
import ApiManager from "../utils/ApiManager";
import { toast } from "react-toastify";

interface AddOfferModalProps {
  isVisible: boolean;
  onHide: () => void;
}

type Offer = {
  pointsRequired: number | string;
  discountValue: number | string;
  discountType: string;
  serviceType: string[];
  validFrom: string;
  validTo: string;
  isOfferActive: boolean;
};

const AddOfferModal = ({ isVisible, onHide }: AddOfferModalProps) => {
  const formik = useFormik<Offer>({
    initialValues: {
      pointsRequired: "",
      discountValue: "",
      discountType: "",
      serviceType: [],
      validFrom: "",
      validTo: "",
      isOfferActive: true,
    },
    validationSchema: Yup.object({
      pointsRequired: Yup.number().required("Points Required is required"),
      discountValue: Yup.number().required("Discount Value is required"),
      discountType: Yup.string().required("Discount Type is required"),
      serviceType: Yup.array()
        .min(1, "At least one service is required")
        .required("This field is required"),
      validFrom: Yup.date()
        .min(getTodayDate(), "Start Date must be today or future date")
        .required("This field is required"),
      validTo: Yup.date()
        .min(Yup.ref("validFrom"), "Valid To must be after the start date")
        .test(
          "is-greater",
          "Expiry date must be after the start date",
          function (value) {
            const { validFrom } = this.parent;
            if (!value) return true; // Pass if endDate is not provided (optional)
            return new Date(value) > new Date(validFrom);
          }
        )
        .nullable(),
      isOfferActive: Yup.boolean().required("Is Offer Active is required"),
    }),
    onSubmit: async (values) => {
      const res = await new ApiManager().addOffer(values);
      if (res.success) {
        toast.success(res.data?.message || "Offer added successfully");
        formik.resetForm();
        onHide();
      } else {
        toast.error(res.data?.message || "Failed to add offer");
      }
    },
  });

  const handleServiceTypeChange = useCallback(
    (service: { label: string; value: string }, checked: boolean) => {
      const currentServiceTypes = [...formik.values.serviceType];
      if (checked) {
        formik.setFieldValue("serviceType", [
          ...currentServiceTypes,
          service.value,
        ]);
      } else {
        formik.setFieldValue(
          "serviceType",
          currentServiceTypes.filter((item) => item !== service.value)
        );
      }
    },
    [formik.values.serviceType, formik.setFieldValue]
  );

  return (
    <Modal
      heading="Add Offer"
      isVisible={isVisible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-7 items-start gap-x-4 gap-y-2">
          <div className="grid col-span-5 grid-cols-2 gap-x-4 gap-y-2">
            <Input
              placeholder="100"
              className="input-primary"
              label="Points Required *"
              name="pointsRequired"
              type="number"
              value={formik.values.pointsRequired}
              onChange={formik.handleChange}
              error={formik.errors.pointsRequired}
              onBlur={formik.handleBlur}
              isInvalid={Boolean(
                formik.touched.pointsRequired && formik.errors.pointsRequired
              )}
            />
            <Input
              type="date"
              label="Valid From *"
              className="input-primary"
              name="validFrom"
              value={formik.values.validFrom}
              onChange={formik.handleChange}
              error={formik.errors.validFrom}
              onBlur={formik.handleBlur}
              isInvalid={Boolean(
                formik.touched.validFrom && formik.errors.validFrom
              )}
            />
            <Select
              placeholder="Select a Discount Type"
              className="select-primary"
              label="Discount Type *"
              name="discountType"
              value={formik.values.discountType}
              onChange={formik.handleChange}
              error={formik.errors.discountType}
              onBlur={formik.handleBlur}
              isInvalid={Boolean(
                formik.touched.discountType && formik.errors.discountType
              )}
              options={discountOptions}
            />
            <Input
              placeholder="Enter Value"
              label={`Of ${
                formik.values.discountType === "flat"
                  ? "(in $)"
                  : formik.values.discountType === "percentage"
                  ? "(in %)"
                  : ""
              } *`}
              className="input-primary mt-1"
              name="discountValue"
              type="number"
              value={formik.values.discountValue}
              onChange={formik.handleChange}
              error={formik.errors.discountValue}
              onBlur={formik.handleBlur}
              isInvalid={Boolean(
                formik.touched.discountValue && formik.errors.discountValue
              )}
            />
          </div>
          <div className="grid col-span-2 grid-cols-1 gap-2">
            <Input
              type="date"
              label="Valid To (Optional)"
              name="validTo"
              className="input-primary"
              value={formik.values.validTo}
              onChange={formik.handleChange}
              error={formik.errors.validTo}
              onBlur={formik.handleBlur}
              isInvalid={Boolean(
                formik.touched.validTo && formik.errors.validTo
              )}
            />
          </div>
          <div className="col-span-7">
            <div className="label">
              <span className="label-text">Service Type *</span>
            </div>
            <div className="flex items-center gap-4 ">
              <Checkbox
                className="flex-shrink-0"
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue(
                      "serviceType",
                      serviceTypes.map((item) => item.value)
                    );
                  } else {
                    formik.setFieldValue("serviceType", []);
                  }
                }}
                label="All"
                checked={serviceTypes.every((item) =>
                  formik.values.serviceType.includes(item.value)
                )}
              />
              {serviceTypes.map((service) => (
                <Checkbox
                  className="flex-shrink-0"
                  key={service.value}
                  onChange={(e) =>
                    handleServiceTypeChange(service, e.target.checked)
                  }
                  label={service.label}
                  checked={
                    formik.values.serviceType.includes(service.value) ||
                    formik.values.serviceType.includes("all")
                  }
                />
              ))}
            </div>
            {formik.touched.serviceType && formik.errors.serviceType ? (
              <div className="label">
                <span className="label-text-alt text-red-600">
                  {formik.errors.serviceType}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex mt-4 gap-2 items-center justify-end">
          <Button
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            type="button"
            variant="outline"
            className="w-20"
          >
            Close
          </Button>
          <Button
            className="w-20"
            isLoading={formik.isSubmitting}
            type="submit"
          >
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddOfferModal;
