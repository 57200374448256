// @ts-nocheck
import dayjs from "dayjs";
import React, { ChangeEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import {
  useCancelServiceMutation,
  useGetServicesQuery,
} from "../api/services/services";
import empty from "../assets/undraw_page_not_found_re_e9o6.svg";
import { statusMap } from "../utils/Enums";
import ConfirmationDialog from "./ConfirmationDialog";
import Pagination from "./Pagination.js";
import { serviceTypeMap } from "./ServicesDashboard";
import ViewFeedback from "./ViewFeedback";

type PropTypes = {
  userId?: string;
  driverId?: string;
};

type FormType = {
  fromDate: string;
  toDate: string;
  past: string;
  type: string;
  [key: string]: string;
};

const initialState = {
  fromDate: "",
  toDate: "",
  past: "",
  type: "",
};

const Table = ({ userId, driverId }: PropTypes) => {
  const [formState, setFormState] = useState<FormType>(initialState);
  const [page, setPage] = useState(1);
  const [selectedFeedback, setSelectedFeedback] = useState<{
    experienceRating: null | number | string;
    feedback: null | string;
    orderId: string;
  }>({ experienceRating: null, feedback: null, orderId: "" });
  const [status, setStatus] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");

  const { response: orders, isFetching: loading } = useGetServicesQuery({
    user: userId,
    driver: driverId,
    page,
    fromdate: formState.fromDate,
    todate: formState.toDate,
    createdago: formState.past,
    type: formState.type,
    limit: 4,
    status,
  });
  const [cancelService, { isLoading: isCancelingService }] =
    useCancelServiceMutation();

  const form = useRef<HTMLFormElement>(null);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (form.current) {
      setFormState({ ...formState, [event.target.name]: event.target.value });
    }
  };

  const handlePageClick = async (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const handleReset = () => {
    if (form.current) {
      form.current.reset();
    }
    setFormState({ fromDate: "", toDate: "", past: "", type: "" });
    setStatus("");
  };

  const handleServiceCancel = async () => {
    const res = await cancelService(selectedServiceId);
    if (res?.data) {
      setSelectedServiceId("");
      toast("Service Deleted Successfully", { type: "success" });
    }
  };

  return (
    <>
      <div className="h-96 w-full mt-10 mb-4">
        <form ref={form} className="w-full flex items-center justify-between">
          <p className="text-xl">Orders</p>
          <div className="flex items-center">
            <div className="flex items-center mr-4">
              <div className="flex items-center">
                <label htmlFor="from_date">From: </label>
                <input
                  onChange={handleChange}
                  name="fromDate"
                  id="from_date"
                  className="w-44 rounded h-8 bg-white ml-2 px-2"
                  type="date"
                />
              </div>
              <div className="flex items-center ml-4">
                <label htmlFor="to_date">To: </label>
                <input
                  onChange={handleChange}
                  name="toDate"
                  id="to_date"
                  className="w-44 rounded h-8 bg-white ml-2 px-2"
                  type="date"
                />
              </div>
            </div>

            <select
              defaultValue=""
              onChange={handleChange}
              name="type"
              className="w-24 h-8 rounded px-2 mr-4"
            >
              <option value="" disabled hidden>
                Type
              </option>
              <option value="package_pickup_meta">Package</option>
              <option value="curbside_pickup_meta">Curb</option>
              <option value="instore_pickup_meta">In Store</option>
            </select>
            <select
              defaultValue=""
              onChange={(e) => setStatus(e.target.value)}
              className="w-24 outline-none bg-white rounded h-8 pl-4 pr-2 mr-4"
            >
              <option value="" disabled hidden>
                Status
              </option>
              <option value="unassigned">Unassigned</option>
              <option value="assigned">Assigned</option>
              <option value="wayToStore">Way To Store</option>
              <option value="arrivedAtPickup">Arrived At Pickup</option>
              <option value="confirmItems">Confirm Items</option>
              <option value="proceedToDropoff">Proceed To Dropoff</option>
              <option value="arrivedAtDropoff">Arrived At Dropoff</option>
              <option value="confirmDropoff">Confirm Dropoff</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
            </select>
            {/* <select
              defaultValue=""
              onChange={handleChange}
              name="past"
              className="w-24 outline-none bg-white rounded-sm h-8 pl-4 pr-2 mr-4"
            >
              <option value="" disabled hidden>
                Time
              </option>
              <option value="">All</option>
              <option value="1">Today</option>
              <option value="7">Last Week</option>
              <option value="30">Last Month</option>
            </select> */}
            <button
              onClick={handleReset}
              type="button"
              className="w-32 h-8 bg-transparent border border-primary text-primary rounded ml-4"
            >
              Clear
            </button>
          </div>
        </form>
        <table className="w-full overflow-x-auto bg-white h-4/5  mt-4">
          <tr className="w-full justify-between h-20% border-b border-gray-200 flex items-center px-4 py-4">
            <td className="w-36 text-black">Date</td>
            <td className="w-36 text-black">Type</td>
            <td className="w-36 text-black">Driver</td>
            <td className="w-36 text-black">Status</td>

            <td className="w-56 text-black">Actions</td>
          </tr>
          {loading === true ? (
            <div className="flex pb-20 items-center justify-center">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {orders?.docs?.length > 0 ? (
                orders?.docs?.map((order) => (
                  <tr className="w-full h-20% border-b justify-between border-gray-200 flex items-center text-left px-4 py-4">
                    <td className="w-36 truncate	 text-gray-500">
                      {dayjs(order?.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="w-36 text-left text-gray-500">
                      {serviceTypeMap[order?.serviceMetaType]}
                    </td>
                    <td className="w-36 truncate	 text-gray-500">
                      {order?.driver?.length === 0
                        ? "N/A"
                        : order.driver[0].fullName}
                    </td>
                    <td
                      className={twMerge(
                        "w-36 truncate text-orange-600",
                        order?.status === "cancelled" && "text-red-600",
                        order?.status === "ratedByUser" && "text-green-600"
                      )}
                    >
                      {statusMap(order?.status)}
                    </td>

                    <td className="w-56 flex items-center gap-6">
                      <Link to={`/dashboard/services/${order?._id}`}>
                        <button className="h-8 rounded text-primary">
                          Details
                        </button>
                      </Link>
                      {driverId && order?.ratingByUser && (
                        <button
                          onClick={() =>
                            setSelectedFeedback({
                              experienceRating: order?.ratingByUser?.rating,
                              feedback: order?.ratingByUser?.feedback,
                              orderId: order?._id,
                            })
                          }
                          className="h-8 rounded text-primary"
                        >
                          View Feedback
                        </button>
                      )}
                      {order?.status ===
                      "ratedByUser" ? null : order?.status ===
                        "cancelled" ? null : (
                        <button
                          onClick={() => setSelectedServiceId(order?._id)}
                          className="text-red-600 ml-4"
                        >
                          Cancel
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div className="w-full flex-col h-80% flex items-center justify-center">
                  <img alt="Empty" className="w-20 h-20" src={empty} />
                  <p>No data found</p>
                </div>
              )}
            </>
          )}
        </table>
        <div className="h-1/5 w-full">
          <Pagination
            setPage={setPage}
            page={orders?.totalPages}
            id={userId || driverId}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
      <ViewFeedback
        feedback={selectedFeedback}
        screen="driverDetails"
        isVisible={selectedFeedback.orderId !== ""}
        setVisible={() =>
          setSelectedFeedback({
            experienceRating: null,
            feedback: "",
            orderId: "",
          })
        }
      />
      <ConfirmationDialog
        isSubmitButtonDisabled={isCancelingService}
        handleClick={handleServiceCancel}
        isVisible={selectedServiceId !== ""}
        onHide={() => setSelectedServiceId("")}
        text="Are you sure you want to cancel this service ?"
      />
    </>
  );
};

export default Table;
